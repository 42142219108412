import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Image from 'next/image';
import {
  faArrowRight,
  faStar,
  faAward,
  faUser,
  faBookReader,
  faUserCog,
  faWindowRestore,
  faUsers,
  faSignature,
  faProjectDiagram,
  faServer,
  faCalendarAlt,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../components/common/button';
import CustomHead from '../components/head';
import heroBgImage from '../public/images/hero-bg.png';
import heroMobileBgImage from '../public/images/mobile-hero-bg.png';
import clanSectionBgImage from '../public/images/feats-bg.jpg';
import hiringImage from '../public/images/elevolt-hiring.png';
import onboardingImage from '../public/images/elevolt-onboarding.png';
import hrisImage from '../public/images/elevolt-hris.png';

const features = [
  {
    title: 'Seamless Applicant Experience',
    description:
      'Elevolt recruitment software helps you organize and manage applicant information. A simple and intuitive hiring process that you and your candidates will actually enjoy.',
    icon: faUser,
  },
  {
    title: 'Interview Scheduling Software',
    description:
      'Transform your interview process with interview scheduling tools that enable you and your team to plan and execute interviews effectively.',
    icon: faStar,
  },
  {
    title: 'Paperless Onboarding',
    description:
      'Replace unending paperwork with an efficient onboarding system that sets up new hires effortlessly using workflows, online document management, e-signatures, notifications, and checklists.',
    icon: faBookReader,
  },
  {
    title: 'Employee Database & Self service',
    description:
      'Manage all your sensitive employee data securely from a centralized portal. Allowing secure access and flexibility for employees to update personal information smoothly.',
    icon: faUserCog,
  },
];

const featuresHiring = [
  {
    title: 'Applicant Tracking System',
    description:
      'With our built-in applicant tracking, recruiters can easily create and customize job openings while keeping applicant information organized to fit their hiring needs.',
    icon: faWindowRestore,
  },
  {
    title: 'Interview Scheduling Software',
    description:
      'Get the right hires with our efficient interview scheduling software that keeps hiring teams on their feet with interview planning, organization, and execution.',
    icon: faStar,
  },
  {
    title: 'Seamless Applicant Experience',
    description:
      'A well-maintained hiring process from application to job offer will speed up the hiring process and sustain good rapport with candidates.',
    icon: faUsers,
  },
];

const featuresOnboarding = [
  {
    title: 'Seamless Onboarding',
    description:
      'Integrate new hires into your organization like a pro with automated job offer letters, data management tools, and electronic signatures.',
    icon: faUsers,
  },
  {
    title: 'E-signatures',
    description:
      'Say goodbye to outdated and inefficient methods of getting documents signed. With E-signatures, users can append their signatures to documents with ease.',
    icon: faSignature,
  },
  {
    title: 'Workflows & Automations',
    description:
      'Assign, manage and follow up on tasks required for your HR process with intuitive workflows and task lists.',
    icon: faProjectDiagram,
  },
];

const featuresHRIS = [
  {
    title: 'Secure Employee Data Storage',
    description:
      'Access, organize, and manage employee data from a centralized portal securely. With a multi-tenant setup, your sensitive data is segregated and encrypted at rest.',
    icon: faServer,
  },
  {
    title: 'Paid Time-off',
    description:
      'Time-of-tracking software provides an easy time-off request-approval workflow for both employees and management.',
    icon: faCalendarAlt,
  },
  {
    title: 'Payroll (Coming Soon)',
    description:
      'Fast, intuitive, and accurate payroll software that will allow you to cruise through payday without breaking a sweat.',
    icon: faFileInvoiceDollar,
  },
];

function Home({ isMobile }) {
  return (
    <>
      <CustomHead
        title="HR Software for small and medium businesses"
        description="Elevolt HR is built and designed for small and medium businesses to manage HR processes efficiently."
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Elevolt',
              url: 'https://www.elevolt.co.ke/',
              logo: 'https://www.elevolt.co.ke/images/logo.png',
              sameAs: [
                'https://twitter.com/ElevoltKe',
                'https://www.instagram.com/elevolt.ke/',
                'https://www.facebook.com/elevoltke',
              ],
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'WebSite',
              name: 'Elevolt',
              url: 'https://www.elevolt.co.ke/',
            }),
          }}
        />
      </CustomHead>
      <div className="w-full pb-32 pt-32 md:pt-16 lg:pb-16 xl:pb-20 relative bg-white flex justify-center items-center overflow-hidden">
        {!isMobile && (
          <div className="w-[90%] bg-image !bottom-8 hidden md:flex my-auto mx-auto z-10 bg-no-repeat">
            <Image src={heroBgImage} quality={100} alt="Elevolt" priority />
          </div>
        )}
        {isMobile && (
          <div className="w-full bg-image flex md:hidden mt-auto mb-0 !bottom-0 z-10 bg-no-repeat">
            <Image
              src={heroMobileBgImage}
              quality={100}
              alt="Elevolt"
              priority
            />
          </div>
        )}
        <div className="container relative hero mx-auto flex flex-col md:justify-center items-start px-4 py-16 md:py-24 bg-cover md:bg-contain bg-center z-20">
          <div className="text-center md:mt-12 w-full">
            <h1 className="text-2xl sm:text-3xl md:text-5xl text-gray-700 capitalize font-bold md:mt-4 tracking-normal leading-none">
              All-in-one HR portal for <br /> your business.
            </h1>
            <p className="text-base md:text-lg font-normal mt-6 text-gray-600 leading-relaxed max-w-[500px] mx-auto">
              Forget the hustle of hiring and HR management and focus on the
              things that matter for your company.
            </p>
            <div className="w-full flex flex-col md:flex-row justify-center items-start mb-6 md:mb-24 mt-6 md:mt-10">
              <div className="w-auto flex flex-col justify-center mx-auto md:mx-0">
                <Button
                  design="primary"
                  href="/register?type=company"
                  id="get-started-hero-btn"
                  className="inline-block"
                  asDefaultATag
                  afterIcon={
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="ml-2 text-sm"
                      size="1x"
                    />
                  }
                  tracked
                  trackData={{
                    action: 'click',
                    label: 'Home Page Get Started Hero Button',
                  }}
                >
                  Get Started Today
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-dark text-blue-200 w-full py-16 md:py-24">
        <div className="container mx-auto px-4">
          <div className="w-full flex flex-col md:flex-row md:items-center">
            <div className="w-full flex flex-col justify-center">
              <div className="w-full max-w-5xl mx-auto flex justify-between">
                <div>
                  <h2 className="text-2xl md:text-3xl mb-1 text-left text-white">
                    Streamline Your HR Activities
                  </h2>
                  <p className="w-full max-w-2xl ml-0 text-base text-left text-blue-100">
                    Whether you&apos;re hiring, onboarding, offboarding,
                    handling compliance, Elevolt gives you the tools that you
                    need to power through it all with ease and efficiency.
                  </p>
                </div>
                <div className="my-auto hidden md:flex">
                  <Button
                    href="/register?type=company"
                    design="secondary"
                    afterIcon={
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="ml-2 text-sm"
                        size="1x"
                      />
                    }
                    tracked
                    trackData={{
                      action: 'click',
                      label: 'Home Page Effecient Hiring Register',
                    }}
                    className="!border-none"
                  >
                    Get Started Now
                  </Button>
                </div>
              </div>
              <div className="w-full max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-14 mt-4 md:mt-8">
                {features.map((ft, index) => (
                  <div
                    className="flex flex-row justify-start w-full py-2 md:py-4 mb-0 md:mb-4"
                    key={`feat-${index}`}
                  >
                    <div className="mt-3 mr-4">
                      <span className="rounded px-3 py-3 bg-orange-400 flex justify-center items-start flex-grow-0 w-10 text-center">
                        <FontAwesomeIcon
                          icon={ft.icon}
                          className="text-base text-white"
                          size="1x"
                        />
                      </span>
                    </div>
                    <div>
                      <h4 className="my-auto mt-3 font-semibold text-white leading-tight md:mb-2">
                        {ft.title}
                      </h4>
                      <p className="text-current">{ft.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex justify-center flex-col mt-4">
                <p className="text-base text-white/80 italic text-center">
                  And much more...
                </p>
                <div className="flex mx-auto mt-3 md:hidden">
                  <Button
                    href="/register?type=company"
                    design="secondary"
                    afterIcon={
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="ml-2 text-sm"
                        size="1x"
                      />
                    }
                    tracked
                    trackData={{
                      action: 'click',
                      label: 'Home Page Effecient Hiring Register',
                    }}
                    className="!border-none"
                  >
                    Get Started Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-full relative flex justify-center items-center">
        <div className="w-[40%] h-72 bg-blue-50 absolute left-0 rounded-r-lg my-auto z-0 hidden md:block" />
        <div className="w-[60%] h-36 bg-blue-50 absolute top-0 rounded-b-lg mx-auto z-0 block md:hidden" />
        <div className="container mx-auto px-4 py-16 md:py-24">
          <div className="w-full flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-6/12 md:pr-16 relative flex flex-col justify-end items-center">
              <div className="rounded-lg overflow-hidden w-full ml-auto relative">
                <Image
                  src={hiringImage}
                  alt="Hire with Elevolt"
                  objectFit="contain"
                  quality={100}
                />
              </div>
            </div>
            <div className="w-full md:w-6/12 mt-8 md:mt-0">
              <div className="w-full">
                <h2 className="text-2xl md:text-3xl mb-1">Hiring</h2>
                <p className="font-medium">
                  Hiring is a tedious and sensitive process. Elevolt give you
                  the tools you need to manage it all, in less time, with less
                  effort.
                </p>
              </div>
              <div className="w-full flex flex-row flex-wrap mt-4 md:mt-6">
                {featuresHiring.map((ft, index) => (
                  <div
                    className="flex flex-row items-start w-full py-2 text-gray-900 mb-4"
                    key={`feat-${index}`}
                  >
                    <span className="rounded px-3 py-3 bg-blue-50 flex justify-center items-start flex-grow-0 w-10 mt-2 text-center">
                      <FontAwesomeIcon
                        icon={ft.icon}
                        className="text-base text-blue"
                        size="1x"
                      />
                    </span>
                    <div className="ml-3">
                      <h4 className="font-semibold text-blue-dark">
                        {ft.title}
                      </h4>
                      <p>{ft.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-blue-dark text-blue-200 w-full flex flex-col items-center justify-center relative">
        <div className="w-[40%] md:w-96 h-72 bg-blue absolute right-0 rounded-l-lg my-auto z-0 hidden md:block" />
        <div className="w-[60%] h-36 bg-blue absolute top-0 rounded-b-lg mx-auto z-0 block md:hidden" />
        <div className="container mx-auto px-4 py-16 md:py-24">
          <div className="w-full flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-6/12 md:pl-16 relative flex flex-col justify-end md:hidden">
              <div className="rounded-lg overflow-hidden h-[250px] md:h-[500px] w-full ml-auto relative">
                <Image
                  src={onboardingImage}
                  layout="responsive"
                  alt="Onboarding with Elevolt"
                  objectFit="contain"
                  quality={100}
                />
              </div>
            </div>
            <div className="w-full md:w-6/12 mt-8 md:mt-0">
              <div className="w-full">
                <h2 className="text-white text-2xl md:text-3xl mb-1">
                  Onboarding
                </h2>
                <p className="text-blue-100 font-medium">
                  Getting new hires onboarded can be a long painstaking process
                  but with Elevolt you get the tools to make this process a
                  smooth one for you and your hires.
                </p>
              </div>
              <div className="w-full flex flex-row flex-wrap mt-4 md:mt-6">
                {featuresOnboarding.map((ft, index) => (
                  <div
                    className="flex flex-row items-start w-full py-2 mb-4"
                    key={`feat-${index}`}
                  >
                    <span className="rounded px-3 py-3 bg-orange-400 mt-2 flex justify-center items-start flex-grow-0 w-10 text-center">
                      <FontAwesomeIcon
                        icon={ft.icon}
                        className="text-base text-white"
                        size="1x"
                      />
                    </span>
                    <div className="ml-3">
                      <h4 className="font-semibold text-white">{ft.title}</h4>
                      <p className="text-current">{ft.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full md:w-6/12 md:pl-16 relative md:flex flex-col justify-end items-center hidden">
              <div className="rounded-lg overflow-hidden w-full ml-auto relative">
                <Image
                  src={onboardingImage}
                  layout="responsive"
                  alt="Onboarding with Elevolt"
                  objectFit="contain"
                  quality={100}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-full flex justify-center items-center relative">
        <div className="w-[40%] md:w-96 h-72 bg-blue-50 absolute left-0 rounded-r-lg my-auto z-0 hidden md:block" />
        <div className="w-[60%] h-36 bg-blue-50 absolute top-0 rounded-b-lg mx-auto z-0 block md:hidden" />
        <div className="container mx-auto px-4 py-16 md:py-24">
          <div className="w-full flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-6/12 md:pr-16 relative flex flex-col justify-end">
              <div className="flex justify-center w-full ml-auto relative">
                <Image
                  src={hrisImage}
                  alt="Onboarding with Elevolt"
                  objectFit="contain"
                  className="rounded-xl"
                  quality={100}
                />
              </div>
              <div className="w-60 flex flex-col justify-center text-center rounded-lg bg-white mb-4 md:mb-0 md:mt-0 md:absolute px-8 py-14 drop-shadow-xl mx-auto md:right-8 md:top-14">
                <div>
                  <FontAwesomeIcon
                    icon={faAward}
                    className="text-24xl text-blue"
                    size="2x"
                  />
                </div>
                <p className="text-center mt-3">
                  Paperless data handling and secure encrypted data storage.
                </p>
              </div>
            </div>
            <div className="w-full md:w-6/12 mt-8 md:mt-0">
              <div className="w-full">
                <h2 className="text-2xl md:text-3xl mb-1">
                  HRIS & Data Managemnt
                </h2>
                <p className="font-medium">
                  Forgot the cabinet with piles of paper and folders that are
                  hard to track. Store, manage and organize employee data with a
                  centralized, secure portal.
                </p>
              </div>
              <div className="w-full flex flex-row flex-wrap mt-4">
                {featuresHRIS.map((ft, index) => (
                  <div
                    className="cat-item flex flex-row items-start w-full py-4 text-gray-900"
                    key={`feat-${index}`}
                  >
                    <span className="rounded px-3 py-3 mb-4 bg-blue-50 flex justify-center items-start flex-grow-0 w-10 mt-2 text-center">
                      <FontAwesomeIcon
                        icon={ft.icon}
                        className="text-base text-blue"
                        size="1x"
                      />
                    </span>
                    <div className="ml-3">
                      <h4 className="font-semibold text-blue-dark">
                        {ft.title}
                      </h4>
                      <p>{ft.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white footer-cta-section bg-cover bg-no-repeat w-full px-4 md:px-0 pb-10">
        <div className="container mx-auto max-w-4xl rounded-lg py-16 md:py-24">
          <div className="w-full flex flex-col md:flex-row justify-center text-center mt-10">
            <div className="w-full md:w-1/2 pr-0 md:pr-6 mb-4 md:mb-0">
              <div className="w-full bg-blue text-blue-100 rounded p-6">
                <span className="bg-orange-400 text-xs font-medium px-3 py-1 text-white rounded-full">
                  Hiring
                </span>
                <h4 className="text-xl font-semibold mt-6 mb-2 text-white leading-none">
                  Post a Job & Start Hiring Today
                </h4>
                <p className="text-current">
                  Let’s get you started on getting your next hire today.
                </p>
                <div className="mt-4">
                  <Button
                    href="/register?type=company"
                    design="secondary"
                    className="!border-none"
                    afterIcon={
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="ml-2 text-sm"
                        size="1x"
                      />
                    }
                  >
                    Post a Job
                  </Button>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 pl-0 md:pl-6">
              <div className="w-full bg-blue text-blue-100 rounded p-6">
                <span className="bg-orange-400 text-xs font-medium px-3 py-1 text-white rounded-full">
                  HR Portal
                </span>
                <h4 className="text-xl font-semibold mt-6 mb-2 text-white leading-none">
                  Explore our all-in-one HR portal
                </h4>
                <p className="text-current">
                  Take the hustle out of managing your HR activities.
                </p>
                <div className="mt-4">
                  <Button
                    href="/register?type=company"
                    design="secondary"
                    className="!border-none"
                    afterIcon={
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="ml-2 text-sm"
                        size="1x"
                      />
                    }
                  >
                    Start Free Trial
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white w-full clan-section relative flex justify-center sm:pt-20 md:pt-28 lg:pt-40 pb-10 md:pb-20 px-4 md:px-0 overflow-x-hidden">
        <div className="w-[200%] md:w-full bg-image top-0 mx-auto z-0">
          <Image src={clanSectionBgImage} alt="Elevolt HR portal" />
        </div>
        <div className="container relative max-w-4xl mt-24 md:mt-8 mx-auto py-10 md:py-24 px-4 bg-white rounded-lg shadow-md md:shadow-xl z-50">
          <div className="w-full flex flex-col">
            <div className="w-full flex flex-col justify-center text-center">
              <div>
                <p className="text-xs md:text-sm font-medium bg-orange-200 rounded-full px-3 py-1 capitalize inline-block mb-2">
                  Companies like yours hire with Elevolt.
                </p>
              </div>
              <h2 className="text-xl md:text-2xl font-semibold text-blue">
                Join Other Industry Leaders
              </h2>
              <p className="text-base mt-4">Just a few of the perks:</p>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-center mt-6">
              <ul className="list-none ml-0 mt-2">
                <li className="mb-2 flex items-center">
                  <span className="rounded px-2 py-2 inline-flex w-8 mr-2 text-center">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-blue"
                      size="sm"
                    />
                  </span>
                  Leverage Machine Learning technologies
                </li>
                <li className="mb-2 flex items-center">
                  <span className="rounded px-2 py-2 inline-flex w-8 mr-2 text-center">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-blue"
                      size="sm"
                    />
                  </span>
                  Custom quizzes and tools to evaluate applicants
                </li>
                <li className=" flex items-center">
                  <span className="rounded px-2 py-2 inline-flex w-8 mr-2 text-center">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-blue"
                      size="sm"
                    />
                  </span>
                  Outstanding applicant experience
                </li>
              </ul>
              <ul className="list-none mt-2 ml-0 md:ml-8">
                <li className="mb-2 flex items-center">
                  <span className="rounded px-2 py-2 inline-flex w-8 mr-2 text-center">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-blue"
                      size="sm"
                    />
                  </span>
                  Seamless communication tools
                </li>
                <li className="mb-2 flex items-center">
                  <span className="rounded px-2 py-2 inline-flex w-8 mr-2 text-center">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-blue"
                      size="sm"
                    />
                  </span>
                  24/7 technical support
                </li>
                <li className="flex items-center">
                  <span className="rounded px-2 py-2 inline-flex w-8 mr-2 text-center">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-blue"
                      size="sm"
                    />
                  </span>
                  Free marketing for featured job posts
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Home.propTypes = {
  isMobile: PropTypes.bool,
};

export async function getServerSideProps(ctx) {
  let userAgent;
  if (ctx.req) {
    userAgent = ctx.req.headers['user-agent'];
  } else {
    userAgent = navigator.userAgent;
  }

  const isMobile = userAgent.match(
    /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
  );

  ctx.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  return {
    props: {
      isMobile,
    },
  };
}

export default Home;
